import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './Home.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FacebookProvider, Page } from 'react-facebook';
import LogoFacebook from 'react-ionicons/lib/LogoFacebook';
import ImgOurFamily from './images/our_family.jpg';
import ImgPlus from './images/plus.png';
import ImgBHSS from './images/Logos/bhss.png';
import ImgAICA from './images/Logos/aica.jpg';
import ImgWFS from './images/Logos/watertown.png';

import ImgCattle1_1 from './images/314P/314P-01.jpg';
//import ImgCattle1_2 from './images/232/232-02.jpg';
//import ImgCattle1_3 from './images/232/232-03.jpg';
import ImgCattle2_1 from './images/306P/306P-01.jpg';
//import ImgCattle2_2 from './images/239/239-02.jpg';
//import ImgCattle2_3 from './images/239/239-03.jpg';
import ImgCattle3_1 from './images/313/313-01.jpg';
import ImgCattle4_1 from './images/315/315-01.jpg';
//import ImgCattle4_2 from './images/104/104-02.jpg';
//import ImgCattle5_1 from './images/921P/921P-1.jpg';
//import ImgCattle5_2 from './images/921P/921P-2.jpg';
//import ImgCattle5_3 from './images/921P/921P-3.jpg';

// Cattle 1: Bull 314 P
const cattle1title = <><strong>JM JAKE SULLY 314P</strong> <i>(AICA Reg. #M989211)</i></>;
const cattle1desc = <><p className="black-text"><b>He sells at the Watertown Farm Show held in Watertown, SD on February 8, 2024<br /></b></p>
    <h4 className="blue-text"><b>Summary</b></h4>
    <table className="table table-bordered table-sm"><tbody><tr className="table-success"><th>Birth Date</th><th>Birth Wt.</th><th>Adj. 205</th><th>Day Gain</th></tr>
        <tr className="table-info"><td>02/06/2023</td><td>97 lbs</td><td>771</td><td>3.95 lbs</td></tr></tbody></table>

    <h4 className="blue-text"><b>EPDs</b></h4>
    <table className="table table-bordered table-sm"><tbody><tr className="table-success"><th>Birth Wt.</th><th>Wng. Wt.</th><th>Yrlg. Wt.</th><th>Milk</th><th>Total Mtnl.</th><th>TSI</th></tr>
        <tr className="table-info"><td>-0.4</td><td>68</td><td>137</td><td>32</td><td>66</td><td>280.82</td></tr></tbody></table></>;
const cattle1carousel_1 = <div key="314P-01"><img src={ImgCattle1_1} className="cattle-image" alt="314P-01"></img></div>;
//const cattle1carousel_2 = <div key="232-02"><img src={ImgCattle1_2} className="cattle-image" alt="232-02"></img></div>;
//const cattle1carousel_3 = <div key="232-03"><img src={ImgCattle1_3} className="cattle-image" alt="232-03"></img></div>;
const cattle1imgtag = <img src={ImgCattle1_1} alt="Bull 314 P" className="featured-cattle-item"></img>;
const cattle1tagline = <div className="featured-cattle-item-name orange-text">JM JAKE SULLY 314P<br /> <small className="white-text"><i>Watertown Farm Show</i></small></div>;

// Cattle 2: Bull 306 P
const cattle2title = <><strong>JM KEVIN MCALLISTER 306P</strong> <i>(AICA Reg. #M989213)</i></>;
const cattle2desc = <><p className="black-text"><b>He sells at the Black Hills Stock Show held in Rapid City, SD on January 30, 2024<br /></b></p>
    <h4 className="blue-text"><b>Summary</b></h4>
    <table className="table table-bordered table-sm"><tbody><tr className="table-success"><th>Birth Date</th><th>Birth Wt.</th><th>Adj. 205</th><th>Day Gain</th></tr>
        <tr className="table-info"><td>01/28/2023</td><td>79 lbs</td><td>681</td><td>4.36 lbs</td></tr></tbody></table>

    <h4 className="blue-text"><b>EPDs</b></h4>
    <table className="table table-bordered table-sm"><tbody><tr className="table-success"><th>Birth Wt.</th><th>Wng. Wt.</th><th>Yrlg. Wt.</th><th>Milk</th><th>Total Mtnl.</th><th>TSI</th></tr>
        <tr className="table-info"><td>-2.4</td><td>58</td><td>105</td><td>21</td><td>51</td><td>249.71</td></tr></tbody></table></>;
const cattle2carousel_1 = <div key="306P-01"><img src={ImgCattle2_1} className="cattle-image" alt="306P-01"></img></div>;
//const cattle2carousel_2 = <div key="239-02"><img src={ImgCattle2_2} className="cattle-image" alt="239-02"></img></div>;
//const cattle2carousel_3 = <div key="239-03"><img src={ImgCattle2_3} className="cattle-image" alt="239-03"></img></div>;
const cattle2imgtag = <img src={ImgCattle2_1} alt="Bull 306 P" className="featured-cattle-item"></img>;
const cattle2tagline = <div className="featured-cattle-item-name orange-text">JM KEVIN MCALLISTER 306P<br /> <small className="white-text"><i>Black Hills Stock Show</i></small></div>;

// Cattle 3: Bull 313 P
const cattle3title = <><strong>JM MR BUCKWHEAT 313 P</strong> <i>(AICA Reg. #M989183)</i></>;
const cattle3desc = <><p className="black-text"><b>He sells at the Black Hills Stock Show held in Rapid City, SD on January 30, 2024<br /></b></p>
    <h4 className="blue-text"><b>Summary</b></h4>
    <table className="table table-bordered table-sm"><tbody><tr className="table-success"><th>Birth Date</th><th>Birth Wt.</th><th>Adj. 205</th><th>Day Gain</th></tr>
        <tr className="table-info"><td>02/05/2023</td><td>88 lbs</td><td>725</td><td>3.30 lbs</td></tr></tbody></table>

    <h4 className="blue-text"><b>EPDs</b></h4>
    <table className="table table-bordered table-sm"><tbody><tr className="table-success"><th>Birth Wt.</th><th>Wng. Wt.</th><th>Yrlg. Wt.</th><th>Milk</th><th>Total Mtnl.</th><th>TSI</th></tr>
        <tr className="table-info"><td>-4.0</td><td>66</td><td>113</td><td>32</td><td>65</td><td>255.63</td></tr></tbody></table></>;
const cattle3carousel_1 = <div key="313-01"><img src={ImgCattle3_1} className="cattle-image" alt="313-01"></img></div>;
const cattle3imgtag = <img src={ImgCattle3_1} alt="Bull 313 P" className="featured-cattle-item"></img>;
const cattle3tagline = <div className="featured-cattle-item-name orange-text">JM MR BUCKWHEAT 313 P<br /> <small className="white-text"><i>Black Hills Stock Show</i></small></div>;

// Cattle 4: Bull 315 P
const cattle4title = <><strong>JM HERE COMES THE BOOM 315 P</strong> <i>(AICA Reg. #M989210)</i></>;
const cattle4desc = <><p className="black-text"><b>He sells at the Watertown Farm Show held in Watertown, SD on February 8, 2024<br /></b></p>
    <h4 className="blue-text"><b>Summary</b></h4>
    <table className="table table-bordered table-sm"><tbody><tr className="table-success"><th>Birth Date</th><th>Birth Wt.</th><th>Adj. 205</th><th>Day Gain</th></tr>
        <tr className="table-info"><td>02/06/2023</td><td>98 lbs</td><td>747</td><td>4.60 lbs</td></tr></tbody></table>

    <h4 className="blue-text"><b>EPDs</b></h4>
    <table className="table table-bordered table-sm"><tbody><tr className="table-success"><th>Birth Wt.</th><th>Wng. Wt.</th><th>Yrlg. Wt.</th><th>Milk</th><th>Total Mtnl.</th><th>TSI</th></tr>
        <tr className="table-info"><td>-0.4</td><td>72</td><td>143</td><td>36</td><td>72</td><td>287.79</td></tr></tbody></table></>;
const cattle4carousel_1 = <div key="315-01"><img src={ImgCattle4_1} className="cattle-image" alt="315-01"></img></div>;
const cattle4imgtag = <img src={ImgCattle4_1} alt="Bull 315 P" className="featured-cattle-item"></img>;
const cattle4tagline = <div className="featured-cattle-item-name orange-text">JM HERE COMES THE BOOM 315 P<br /> <small className="white-text"><i>Watertown Farm Show</i></small></div>;

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            title: '',
            content: '',
            carousel: ''
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    setModalTitle = (textTitle) => {
        this.setState({
            title: textTitle
        });
    }

    setModalContent = (textDesc) => {
        this.setState({
            content: textDesc
        });
    }

    setModalCarousel = (textCarousel) => {
        this.setState({
            carousel: textCarousel
        });
    }

    toggleAndSet = (index) => {
        this.toggle();
        switch (index) {
            case 1:
                {
                    this.setModalTitle(cattle1title);
                    this.setModalContent(cattle1desc);
                    this.setModalCarousel([cattle1carousel_1]);
                    //example for future use: use an array! this.setModalCarousel([cattle1carousel_1, cattle1carousel_1, cattle1carousel_1]);
                    return;
                }
            case 2:
                {
                    this.setModalTitle(cattle2title);
                    this.setModalContent(cattle2desc);
                    this.setModalCarousel([cattle2carousel_1]);
                    return;
                }
            case 3:
                {
                    this.setModalTitle(cattle3title);
                    this.setModalContent(cattle3desc);
                    this.setModalCarousel([cattle3carousel_1]);
                    return;
                }
            case 4:
                {
                    this.setModalTitle(cattle4title);
                    this.setModalContent(cattle4desc);
                    this.setModalCarousel([cattle4carousel_1]);
                    return;
                }/*
            case 5:
                {
                    this.setModalTitle(cattle5title);
                    this.setModalContent(cattle5desc);
                    this.setModalCarousel([cattle5carousel_1, cattle5carousel_2, cattle5carousel_3]);
                    return;
                }*/
            default:
                return;
        }
    }

    render() {
        var settings = {
            autoplay: true,
            autoplaySpeed: 2000,
            dots: true,
            fade: true,
            adaptiveHeight: true,
            slidesToShow: 1,
            pauseOnFocus: false,
            pauseOnHover: false,
            pauseOnDotsHover: false,
            useCSS: true,
            variableWidth: false
        };

        return (
            <div>

                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>{this.state.title}</ModalHeader>
                    <ModalBody>
                        {this.state.content}
                        <Slider {...settings}>{this.state.carousel}</Slider>
                        <div></div>
                    </ModalBody>
                </Modal>

                <React.Fragment>
                    <section className="intro" id="intro">
                        <div className="container">
                            <div className="intro-table">
                                <div className="intro-wrapper">
                                    <h1 className="intro-title">
                                        J&M Ranch
                                    </h1>
                                    <p className="intro-subtitle">
                                        since 2014
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </React.Fragment>

                <React.Fragment>
                    <section className="about-jmranch white-background" id="about-jmranch">
                        <div className="container">
                            <div className="section-wrapper less-bottom-pad">
                                <h2 className="section-title">
                                    <span className="about-jmranch-span orange-text underline-blue">Our Ranch</span>
                                </h2>
                                <img src={ImgOurFamily} alt="Our Ranch" className="about-jmranch-img"></img>
                                <div className="about-jmranch-text">
                                    J&M Ranch is located in Lake Preston, SD.  It was founded by Jerod and Melanie Olson in 2014 when the couple took a leap of faith and invested themselves wholly in learning the ropes of growing their own business. <br /><br />
                                    Melanie grew up around Charolais cattle her entire life being the youngest daughter of Al and Deb Vedvei of Vedvei Charolais. Jerod grew up in a commercial herd and then spent 3 years working with Al before establishing J&M Ranch. <br /><br />
                                    J&M Ranch currently consists of approximately 80 head.  The Olsons focus on producing Charolais cattle with low birth weights and high performance.  They avoid "fads" and work to produce cattle that will add profit to the commercial producers bottom line.  <br /><br />
                                    Bulls are marketed annually by private treaty as well as consigning to area winter show and sales. J&M Ranch Charolais won South Dakota Seedstock Producer of the Year in 2019 and was the South Dakota Charolais Family of the Year in 2020.<br /><br />
                                    The couple has 2 daughters, Khloe (12) and Kinzlee (10), and 2 sons, Kash (7) and Kolt (4).
                                </div>
                            </div>
                        </div>
                    </section>
                </React.Fragment>

                <React.Fragment>
                    <section className="featured-cattle blue-background less-bottom-pad" id="featured-cattle">
                        <div className="container section-wrapper">
                            <h2 className="section-title">
                                <span className="featured-cattle-span orange-text underline-white">Featured Cattle</span>
                            </h2>

                            <div className="row">
                                <div className="col-sm-6 col-xs-6">
                                    <div className="featured-cattle-item">
                                        <div className="featured-cattle-img">
                                            {cattle1imgtag}
                                            <div className="featured-cattle-img-hover" onClick={() => this.toggleAndSet(1)} style={{ cursor: "pointer" }}>
                                                <img src={ImgPlus} alt="plus" className="plus"></img>
                                            </div>
                                        </div>
                                        <div className="featured-cattle-item-details">
                                            {cattle1tagline}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xs-6">
                                    <div className="featured-cattle-item">
                                        <div className="featured-cattle-img">
                                            {cattle2imgtag}
                                            <div className="featured-cattle-img-hover" onClick={() => this.toggleAndSet(2)} style={{ cursor: "pointer" }}>
                                                <img src={ImgPlus} alt="plus" className="plus"></img>
                                            </div>
                                        </div>
                                        <div className="featured-cattle-item-details">
                                            {cattle2tagline}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xs-6">
                                    <div className="featured-cattle-item">
                                        <div className="featured-cattle-img">
                                            {cattle3imgtag}
                                            <div className="featured-cattle-img-hover" onClick={() => this.toggleAndSet(3)} style={{ cursor: "pointer" }}>
                                                <img src={ImgPlus} alt="plus" className="plus"></img>
                                            </div>
                                        </div>
                                        <div className="featured-cattle-item-details">
                                            {cattle3tagline}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xs-6">
                                    <div className="featured-cattle-item">
                                        <div className="featured-cattle-img">
                                            {cattle4imgtag}
                                            <div className="featured-cattle-img-hover" onClick={() => this.toggleAndSet(4)} style={{ cursor: "pointer" }}>
                                                <img src={ImgPlus} alt="plus" className="plus"></img>
                                            </div>
                                        </div>
                                        <div className="featured-cattle-item-details">
                                            {cattle4tagline}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </React.Fragment>

                <React.Fragment>
                    <section className="bulls-for-sale orange-background" id="bulls-for-sale">
                        <div className="container section-wrapper">
                            <h2 className="section-title">
                                <span className="about-jmranch-span white-text underline-blue">Full Bull List</span>
                            </h2>

                            <iframe title="Bull List" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRljVsvSGR-nOSDIi4NHU3QO-eIsdq8Sa14hpwTxy-3u4lAyK7y0nifaMyxdYOm1U4cDavELvY2JBou/pubhtml?widget=true&amp;headers=false" className="iframe-bull-list"></iframe>
                            <a href="https://docs.google.com/spreadsheets/d/e/2PACX-1vRljVsvSGR-nOSDIi4NHU3QO-eIsdq8Sa14hpwTxy-3u4lAyK7y0nifaMyxdYOm1U4cDavELvY2JBou/pubhtml/pub?output=xlsx" className="blue-text">Download</a> &emsp;
                            <a href="https://docs.google.com/spreadsheets/d/e/2PACX-1vRljVsvSGR-nOSDIi4NHU3QO-eIsdq8Sa14hpwTxy-3u4lAyK7y0nifaMyxdYOm1U4cDavELvY2JBou/pubhtml/pubhtml" target="_blank" className="blue-text" rel="noopener noreferrer">Open</a>
                        </div>
                    </section>
                </React.Fragment>

                <React.Fragment>
                    <section className="fun white-background" id="links">
                        <div className="container section-wrapper text-center">
                            <h2 className="section-title">
                                <span className="about-jmranch-span orange-text underline-blue">Links</span>
                            </h2>

                            <div className="row">
                                <div className="col-sm-6">
                                    <a href="http://charolaisusa.com/" target="_blank" rel="noopener noreferrer">
                                        <img src={ImgAICA} alt="American International Charolais Association" className="img-logo" />
                                    </a>
                                    <a href="http://charolaisusa.com/" target="_blank" rel="noopener noreferrer">
                                        <p className="blue-text">American International Charolais Association</p>
                                    </a>
                                </div>
                                <div className="col-sm-6">
                                    <a href="http://www.watertownwinterfarmshow.com/" target="_blank" rel="noopener noreferrer">
                                        <img src={ImgWFS} alt="Watertown Winter Farm Show" className="img-logo" />
                                    </a>
                                    <a href="http://www.watertownwinterfarmshow.com/" target="_blank" rel="noopener noreferrer">
                                        <p className="blue-text">Watertown Winter Farm Show</p>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </section>
                </React.Fragment >

                <React.Fragment >
                    <section className="featured-cattle blue-background" id="contact-info">
                        <div className="container section-wrapper">
                            <h2 className="section-title">
                                <span className="about-jmranch-span orange-text underline-white">Contact Info</span>
                            </h2>

                            <div className="contact-group">

                                <div className="contact-text white-text">
                                    <p><b>
                                        J & M Ranch <br />
                                        20629 443rd Ave. <br />
                                        Lake Preston, SD 57249
                                    </b></p>
                                </div>

                                <div className="contact-text white-text">
                                    <p><b><a className="orange-text" href="mailto:jmranch.charolais@gmail.com" target="_blank" rel="noopener noreferrer">jmranch.charolais@gmail.com</a><br />
                                        Jerod Cell: 605-860-2080</b></p>
                                </div>

                            </div>

                            <div className="contact-group">
                                <iframe title="Google Maps" width="500" height="350" frameBorder="0" style={{ border: "0" }} src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJaTFBX6tGiIcRQQUt2RXX4Lg&key=AIzaSyAje4q-U4I1ezRaiFImzwPYe7HLImWaPzA&zoom=6" allowFullScreen></iframe>
                            </div>
                        </div>
                    </section>
                </React.Fragment >

                <React.Fragment >
                    <section className="featured-cattle orange-background" id="media">
                        <div className="container section-wrapper">
                            <h2 className="section-title">
                                <span className="social-media-feed-span white-text underline-blue">Social Media Feed</span>
                            </h2>
                            <span className="big-screen">
                                <FacebookProvider appId="1202519279838646" className="bigScreen">
                                    <Page href="https://www.facebook.com/Jmranchcharolais/" tabs="timeline, events, messages" width="500" />
                                </FacebookProvider>
                            </span>
                            <span className="small-screen">
                                <FacebookProvider appId="1202519279838646" className="smallScreen">
                                    <Page href="https://www.facebook.com/Jmranchcharolais/" tabs="timeline, events, messages" />
                                </FacebookProvider>
                            </span>

                        </div>
                    </section>
                </React.Fragment >

                <React.Fragment >
                    <footer>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="copy">
                                        &copy;2025 J&M Ranch
                                    </div>
                                </div>
                                <div className="col text-center">

                                    <a href="https://www.facebook.com/Jmranchcharolais" target="_blank" rel="noopener noreferrer">
                                        <div className="icon">
                                            <LogoFacebook fontSize="20px" color="#3b5998" />
                                        </div>
                                    </a>
                                </div>
                                <div className="col text-right">
                                    <div className="copy">
                                        Theme by <a href="http://www.themewagon.com" target="_blank" rel="noopener noreferrer">themewagon.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </React.Fragment >
            </div>
        );
    }
}
